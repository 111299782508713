<template>
  <div class="row">
    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align:right">
      <c-btn
        label="사진설명 저장"
        icon="save"
        v-if="editable&&!disabled"
        @btnClicked="saveData"/>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:25px !important;">
      <c-upload 
        :attachInfo="attachInfo"
        :editable="editable&&!disabled"
        :isPhotoView="true"
        label="피해정도 사진첨부">
      </c-upload>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
export default {
  name: 'process-relation-picture',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_DEGREE_DAMAGE',
        taskKey: '',
      },
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isEnvSafDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000025') > -1 || this.$store.getters.user.empNo === 'admin'
    },
    disabled() {
      return this.param.stepCd === 'ISPC000005' && !this.isEnvSafDept
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo, 'taskKey', this.param.iimAccidentId)
    },
    saveData() {
      this.$set(this.attachInfo, 'isSubmit', uid())
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
  }
};
</script>